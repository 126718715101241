/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  width: 15rem;
  -webkit-transition: width 0.25s ease-out;
  -moz-transition: width 0.25s ease-out;
  -o-transition: width 0.25s ease-out;
  transition: width 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.layoutSidebar {
  -webkit-box-shadow: 10px 0px 20px -13px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 0px 20px -13px rgba(0, 0, 0, 0.25);
  box-shadow: 10px 0px 20px -13px rgba(0, 0, 0, 0.25);
}

.layoutSidebar .logo-button {
  background-color: transparent;
  border: none;
  padding: 5px;
}

.layoutSidebar .logo-button img {
  height: 40px;
}

#alt-logo-button {
  display: none;
}

.layoutSidebar .alt-logo-button,
#alt-command-button {
  display: none;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

.layoutSidebar .list-group-item {
  border: 0;
  color: #0ad48b;
  padding: 0.75rem 1.75rem 0.75rem 3.25rem;
}

.layoutSidebar .list-group-item img {
  height: 35px;
  margin-right: 3px;
  opacity: 0.4;
}

.layoutSidebar .list-group-item span {
  opacity: 0.65;
}

.layoutSidebar .list-group-item:hover {
  background: #0ad48b1a;
}

.layoutSidebar .list-group-item:hover img {
  opacity: 1;
}

.layoutSidebar .list-group-item:hover span {
  opacity: 1;
}

.layoutSidebar .list-group-item.active img {
  opacity: 1;
}

.layoutSidebar .list-group-item.active span {
  opacity: 1;
}

.layoutSidebar .list-group-item.active {
  border: 0;
  color: #0ad48b;
  background: none;
  font-weight: 750;
}

/*! TOGGLED  */

#wrapper.toggled #sidebar-wrapper {
  width: 4rem;
  transition: width 0.25s ease-out;
}

#wrapper.toggled .layoutSidebar .sidebar-heading {
  padding: 0.875rem 0.75rem;
}

#wrapper.toggled .layoutSidebar .logo-button {
  display: none;
}

#wrapper.toggled .layoutSidebar #alt-logo-button {
  display: block;
  padding: 0px;
}

#wrapper.toggled .layoutSidebar #command-button,
#wrapper.toggled .layoutSidebar #command-menu {
  display: none;
}

#wrapper.toggled .layoutSidebar #alt-command-button {
  display: block;
  margin-top: 3.7rem !important;
}

#wrapper.toggled .list-group {
  width: 100%;
}

#wrapper.toggled .list-group-item {
  width: 100%;
  padding: 0.75rem 1.75rem 0.75rem 1.25rem;
}

#wrapper.toggled .list-group-item span {
  display: none;
}

/* #wrapper.toggled .list-group-item:hover {
  width: 150px;
}

#wrapper.toggled .list-group-item:hover span {
  display: inline;
} */

/*! END TOGGLED  */

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

/*! SIDEBAR RESPONSIVE  */

/* @media (max-width: 768px) {
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -16rem;
  }

  #wrapper #sidebar-wrapper {
    margin-left: 0rem;
  }
} */

/*! END SIDEBAR RESPONSIVE  */

.layoutNavbar {
  -webkit-box-shadow: 0px 10px 20px -13px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 10px 20px -13px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 10px 20px -13px rgba(0, 0, 0, 0.25);
}

.layoutNavbar h6 {
  margin-top: 8px;
}

.layoutNavbar .dropdown-menu {
  right: 0;
  left: auto;
}

.container-main {
  padding-left: 35px;
  padding-right: 35px;
}

.container-header {
  color: #0ad48b;
}

.group {
  border: 2px solid #95edcd;
  border-radius: 15px;
}

.no-padding {
  padding: 0;
}

.h-70vh {
  height: 70vh;
}

.form-select {
  width: 40%;
  margin-left: 30%;
  border: 0.5px solid #95edcd;
}

.alt-table-header .row {
  color: white;
  background: #0ad48b;
  border-bottom: 1px solid #95edcd;
  margin: 0;
  font-weight: 700;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.alt-table-header .row .col {
  padding: 6px 0 6px 0;
}

.alt-table-header .row .col:not(:last-child) {
  border-right: 1px solid white;
}

.alt-table-body.body-background {
  background: #f3fdf9;
}

.alt-table-body .row {
  margin: 0;
}

.alt-table-body.bordered .row {
  border-bottom: 1px solid #95edcd;
}

.alt-table-body .row .col {
  padding: 12px 0 10px 0;
}

.alt-table-body .row .col:not(:last-child) {
  border-right: 1px solid #95edcd;
}

.alt-table-body .row .btn-light {
  border: 1px solid black;
  border-radius: 8px;
}

.alt-table-footer .row {
  /* border-bottom: 1px solid #0ad48b; */
  border-radius: 12px;
  margin: 0 0;
}

.alt-table-footer .row .col {
  padding: 12px 0 12px 0;
}

.alt-table-footer .row .col:not(:last-child) {
  border-right: 1px solid #95edcd;
}

input[type='text'] {
  /* border: 1px solid #95edcd; */
}
