.vh100 {
  height: 100vh;
}

.h-85 {
  height: 85%;
}
.auth-layout-left {
  background-size: cover !important;
  background-position: center !important;
}

.auth-layout-right .login-input-group {
  position: relative;
  margin: 40px 0;
}

.auth-layout-right textarea {
  resize: none;
}

.auth-layout-right input:not([type='checkbox']),
.auth-layout-right textarea {
  background: none;
  color: #686868;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 320px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
}
.auth-layout-right input:not([type='checkbox']):focus,
.auth-layout-right textarea:focus {
  outline: none;
}
.auth-layout-right input:not([type='checkbox']):focus ~ label,
.auth-layout-right input:not([type='checkbox']):valid ~ label,
.auth-layout-right textarea:focus ~ label,
.auth-layout-right textarea:valid ~ label {
  top: -14px;
  background: white;
  padding: 2px;
  font-size: 12px;
  color: #19d38a;
}
.auth-layout-right input:not([type='checkbox']):focus ~ .bar:before,
.auth-layout-right textarea:focus ~ .bar:before {
  width: 320px;
}

.auth-layout-right .login-input-group label {
  color: #9e9e9e;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}

.auth-layout-right .bar {
  position: relative;
  display: block;
  width: 320px;
}
.auth-layout-right .bar:before {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #19d38a;
  transition: 300ms ease all;
  left: 0%;
}

.auth-layout-right .form-check-label {
  color: #19d38a !important;
}

.theme-primary-button {
  background: #19d38a !important;
  color: #fff;
  border: 0.5px solid #51dea7 !important;
  padding: 8px 30px;
}

.theme-primary-button:hover {
  background: #19d38a !important;
  color: #fff;
  border: 0.5px solid #51dea7 !important;
  padding: 8px 30px;
}

.theme-primary-outline-button {
  background: #fff;
  color: #19d38a !important;
  border: 1px solid #19d38a !important;
  padding: 8px 30px;
}

.theme-secondary-button {
  background: #518bc8 !important;
  color: #fff;
  border: 0.5px solid #9bbcdf !important;
  padding: 8px 30px;
}

.theme-secondary-outline-button {
  background: #fff;
  color: #518bc8 !important;
  border: 1px solid #518bc8 !important;
  padding: 8px 30px;
}

.theme-secondary-outline-button:hover {
  background: #518bc8;
  color: #fff !important;
  border: 1px solid #518bc8 !important;
  padding: 8px 30px;
}
